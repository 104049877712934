import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchAllArticles } from 'state/actions/allArticlesActions';
import * as Types from 'lib/types';

import globalSettingsSelector from 'state/selectors/globalSettings';
import articlesByTagSelector from 'state/selectors/articlesBytag';
import { AppState } from 'state/types';
import { Status } from 'constants/Status';

interface StateProps {
  globalSettings: ReturnType<typeof globalSettingsSelector>;
  blog: {
    articlesByTag: ReturnType<typeof articlesByTagSelector>;
    allArticles: Types.Article[];
  };
  fetchAllArticlesStatus: Status;
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    globalSettings: globalSettingsSelector(state),
    blog: {
      articlesByTag: articlesByTagSelector(state),
      allArticles: state.blog.allArticles
    },
    fetchAllArticlesStatus: state.status.fetchAllArticles
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllArticles: bindActionCreators(fetchAllArticles, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class BlogIndexContainer extends ContainerBase {
  view: any = import('views/BlogIndexView');
  model = async () => {
    const { fetchAllArticles } = this.props;

    try {
      const allArticles = await fetchAllArticles();
      return allArticles;
    } catch (error) {
      return null;
    }
  };
}

export default connector(BlogIndexContainer);
