import Content from 'lib/Content';

export const FETCH_GENERIC_PAGE = 'FETCH_GENERIC_PAGE';
export const fetchGenericPage = (path) => (dispatch) => {
  return dispatch({
    type: FETCH_GENERIC_PAGE,
    payload: Content.getEntries({
      content_type: 'genericPage',
      'fields.slug': path,
      include: 4
    })
  });
};
