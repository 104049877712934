import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import FatalError from 'components/FatalError';

class ErrorBoundary extends Component {
  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      if (errorInfo && errorInfo.componentStack) {
        scope.setExtra('componentStack', errorInfo.componentStack);
      }
      Sentry.captureException(error);
    });
  }

  render() {
    const { flushStateAndReload } = this.props;

    if (this.state.error) {
      return <FatalError showReloadTrigger handleClick={flushStateAndReload} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
