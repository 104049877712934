import { Button } from 'components/base';
import * as React from 'react';
import { useState } from 'react';
import { BaseCartLine } from 'types/generated-shopify';
import {
  formatMoney,
  getSubtitle,
  getPreorderStatus,
  getEstimatedShipDate
} from 'utils/shopify';
import CartLineItemQuantity from './CartLineItemQuantity';
import { useShopify } from 'lib/Shopify/ShopifyProvider';
import { productIsLightPhone } from 'utils/shopify';
import filterFalsy from 'utils/filterFalsy';
import { lineItemIsNaPhone, findSimLineItem } from './shared';

interface CartLineItemProps {
  lineItem: BaseCartLine;
  lineItems: BaseCartLine[];
}

const CartLineItem: React.FC<CartLineItemProps> = ({ lineItem, lineItems }) => {
  const { removeLineItems } = useShopify();
  const variant = lineItem.merchandise;
  const product = variant.product;

  const [isNAPhone] = useState(lineItemIsNaPhone(lineItem));

  const handleRemoveClick = () => {
    const idsToRemove = [lineItem.id];

    if (isNAPhone) {
      const simLineItem = findSimLineItem(lineItems);
      if (simLineItem) idsToRemove.push(simLineItem.id);
    }

    removeLineItems(idsToRemove);
  };

  const acceptingPreOrders = variant && getPreorderStatus(variant) === 'true';
  const isPreOrder =
    acceptingPreOrders &&
    variant.quantityAvailable != null &&
    variant.quantityAvailable <= 0;
  const productTitle = isPreOrder
    ? product.title + ' (pre-order)'
    : product.title;
  const estimatedShipDate = variant && getEstimatedShipDate(variant);

  /**
   * Shopify gives un-named variants the title 'Default Title'; this includes
   * products with only one variant. Let's skip it if that is the case. */
  const variantTitle =
    variant.title === 'Default Title' ? undefined : variant.title;

  /* The Light Phone products should use their subtitle as an extra bit
   * of description when viewed in teh cart. I.e.:
   *
   * Light Phone II
   * International, Dark
   * */
  const description = productIsLightPhone(product)
    ? filterFalsy([variantTitle, getSubtitle(product)]).join(', ')
    : variantTitle;
  return (
    <div className="Cart__lineItem">
      <div className="akkurat flex flex-col">
        <span className="sub-title">{productTitle}</span>
        {isPreOrder && (
          <span className="description-sm mt_25">
            Estimated Ship: {estimatedShipDate}
          </span>
        )}
        <span className="description-sm mt_25">{description}</span>
      </div>
      <CartLineItemQuantity lineItem={lineItem} lineItems={lineItems} />
      <div className="flex flex-col items-end">
        <span className="akkurat sub-title nowrap">
          {formatMoney(lineItem.cost.subtotalAmount, { includeCents: true })}
        </span>
        <Button
          className="mt_25"
          ariaLabel={`remove ${productTitle} from the cart`}
          onClick={handleRemoveClick}
        >
          <span className="LineItem__remove akkurat description-sm color-gray">
            remove
          </span>
        </Button>
      </div>
    </div>
  );
};

export default CartLineItem;
