import * as React from 'react';

import { Button } from 'components/base';

interface ErrorMessageProps {
  errorMessage: string;
  buttonLabel: string;
  buttonLink: string;
  color?: 'black' | 'white';
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
  buttonLabel,
  buttonLink,
  color = 'white'
}) => {
  return (
    <div className="relative flex flex-col justify-center items-center">
      <h2 className={`block-headline italic color-${color} my1_5`}>
        {errorMessage}
      </h2>
      <div className="my1_5">
        <Button
          variant="primary"
          color={`transparent-${color}-border`}
          label={buttonLabel}
          to={buttonLink}
        />
      </div>
    </div>
  );
};

export default ErrorMessage;
