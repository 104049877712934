import get from 'utils/get';

const flattenFragment = (fragments) => {
  return get(fragments, 'fragments', []).reduce(
    (flattenedFragments, fragment) => {
      const sanitizedFragment = {};
      const getFragment = fragment.length ? fragment : [];
      getFragment.forEach((data) => {
        const camelCasedKey = get(data, 'key', '')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
          })
          .replace(/\s+/g, '');

        const type = get(data, 'type', '');
        const value = data.value;
        if (type === 'Blob') {
          return (sanitizedFragment[camelCasedKey] = value
            ? value
            : {
                data: '',
                name: '',
                size: 0,
                type: ''
              });
        }
        if (type === 'Symbol') {
          return (sanitizedFragment[camelCasedKey] = value ? value : '');
        }
      });

      flattenedFragments.push(sanitizedFragment);
      return flattenedFragments;
    },
    []
  );
};

export default flattenFragment;
