const getUrlParams = (param) => {
  const search = window.location.search.slice(1);
  const searchParams = search
    .split('&')
    .reduce((sanitizedSearchParams, searchParam) => {
      const searchParamInArray = searchParam.split('=');
      sanitizedSearchParams[searchParamInArray[0]] = searchParamInArray[1];

      return sanitizedSearchParams;
    }, {});

  const searchParam = searchParams[param];

  if (!!searchParam) {
    return searchParam;
  } else {
    return '';
  }
};

export default getUrlParams;
