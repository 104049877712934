import React from 'react';

import { Image } from 'components/base';

const Loader = () => {
  return (
    <div>
      <div className="Loader w100 vh100 flex bg-color-white p2 relative flex justify-center items-center">
        <Image
          alt="the light phone logo"
          className="Loader__image"
          src="/assets/images/light-logo-light.svg"
        />
      </div>
    </div>
  );
};

export default Loader;
