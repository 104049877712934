const dev = {
  LIGHT_SITE_DOMAIN: process.env.REACT_APP_LIGHT_SITE_DOMAIN_DEV,
  LIGHT_DASH_URL: process.env.REACT_APP_LIGHT_DASH_URL_DEV,
  XSTATE_DEV_TOOLS_ENABLED: true,
  LIGHT_SITE_URL: process.env.REACT_APP_LIGHT_SITE_URL_STAGING,
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_PREVIEW_API_TOKEN,
  DISABLE_CHECKOUT: false,
  USE_AXIOS: true,

  /* Shopify */
  SHOPIFY_STOREFRONT_SHOP_NAME: 'the-light-phone-development',
  SHOPIFY_STOREFRONT_TOKEN: '2a51a12c168ad6c260caca705a8cce8d',
  SHOPIFY_SIM_VARIANT_ID: 'gid://shopify/ProductVariant/46422526067007'
};

const staging = {
  LIGHT_SITE_DOMAIN: process.env.REACT_APP_LIGHT_SITE_DOMAIN_STAGING,
  LIGHT_DASH_URL: process.env.REACT_APP_LIGHT_DASH_URL_STAGING,
  XSTATE_DEV_TOOLS_ENABLED: true,
  LIGHT_SITE_URL: process.env.REACT_APP_LIGHT_SITE_URL_STAGING,
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_PREVIEW_API_TOKEN,
  DISABLE_CHECKOUT: false,
  USE_AXIOS: true,

  /* Shopify */
  SHOPIFY_STOREFRONT_SHOP_NAME: 'the-light-phone-development',
  SHOPIFY_STOREFRONT_TOKEN: '2a51a12c168ad6c260caca705a8cce8d',
  SHOPIFY_SIM_VARIANT_ID: 'gid://shopify/ProductVariant/46422526067007'
};

const production = {
  LIGHT_SITE_DOMAIN: process.env.REACT_APP_LIGHT_SITE_DOMAIN_PRODUCTION,
  LIGHT_DASH_URL: process.env.REACT_APP_LIGHT_DASH_URL_PRODUCTION,
  XSTATE_DEV_TOOLS_ENABLED: false,
  LIGHT_SITE_URL: process.env.REACT_APP_LIGHT_SITE_URL_PRODUCTION,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_API_TOKEN,
  DISABLE_CHECKOUT: false,
  USE_AXIOS: true,

  /* Shopify */
  SHOPIFY_STOREFRONT_SHOP_NAME: 'goinglight',
  SHOPIFY_STOREFRONT_TOKEN: '29c4a8c2173174e57a3ffb91accc78a2',
  SHOPIFY_SIM_VARIANT_ID: 'gid://shopify/ProductVariant/45890782658836'
};

let toExport;
if (process.env.REACT_APP_STAGE === 'dev') {
  toExport = dev;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  toExport = staging;
} else {
  toExport = production;
}

export default toExport;
