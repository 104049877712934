import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'components/base';

import { initializeApplication } from 'state/actions/applicationActions';
import { handleServerErrors } from 'state/actions/handleErrorActions';
import { closeMenu } from 'state/actions/ui/navUIActions';

import { IDLE, PENDING, FULFILLED, REJECTED } from 'constants/Status';
import Routes from 'routes';
import get from 'utils/get';
import isModalPage from 'utils/isModalPage';
import flattenedImageModel from 'models/flattenedImageModel';
import globalSettings from 'state/selectors/globalSettings';

import 'styles/app.scss';
import 'cookieconsent';
import 'whatwg-fetch';
import 'what-input';

import Nav from 'components/Nav';
import ErrorBoundary from 'components/ErrorBoundary';
import FatalError from 'components/FatalError';
import Menu from 'components/Menu';
import Footer from 'components/Footer';
import FlashMessage from 'components/FlashMessage';
import Loader from 'components/Loader';

const INIT_APPLICATION_THRESHOLD = 3;

class App extends Component {
  state = {
    initApplicationRetries: 0
  };

  componentDidMount() {
    const { initializeApplicationStatus, actions } = this.props;
    if (initializeApplicationStatus === IDLE) {
      actions.initializeApplication();
    }

    window.addEventListener('load', this.addCookieconsent);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.addCookieconsent);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.initializeApplicationStatus === PENDING &&
      this.props.initializeApplicationStatus === REJECTED &&
      this.props.initializeApplicationError
    ) {
      const { actions, initializeApplicationError } = this.props;
      this.incrementInitApplicationRetries();

      return actions.handleServerErrors(
        get(initializeApplicationError, `errors[0]`),
        true
      );
    }

    if (
      prevProps.initializeApplicationStatus === PENDING &&
      this.props.initializeApplicationStatus === FULFILLED &&
      this.state.initApplicationRetries > 0
    ) {
      this.resetInitApplicationRetries();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  incrementInitApplicationRetries = () =>
    this.setState((prevState) => ({
      initApplicationRetries: (prevState.initApplicationRetries += 1)
    }));

  resetInitApplicationRetries = () =>
    this.setState({ initApplicationRetries: 0 });

  ensureFacebookPixelAdded = () => {
    const code = `
<!-- Facebook Pixel Code -->
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '970311700156181');
fbq('track', 'PageView');
`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    try {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    } catch (e) {
      script.text = code;
      document.body.appendChild(script);
    }

    try {
      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.src =
        'https://www.facebook.com/tr?id=970311700156181&ev=PageView&noscript=1';
      img.height = 1;
      img.width = 1;
      img.style = 'display:none';

      noscript.appendChild(img);
      document.body.appendChild(noscript);
    } catch (e) {}
  };

  addCookieconsent = () => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#000'
        },
        button: {
          background: 'transparent',
          text: '#ffffff',
          border: '#ffffff'
        }
      },
      content: {
        message: 'The internet can be sketchy.',
        dismiss: 'acknowledged',
        link: 'view our cookie policy',
        href: '/cookie-policy'
      },
      enabled: 'true',
      onPopupOpen: function () {},
      onPopupClose: function () {},
      onInitialise: (status) => {
        if (status === window.cookieconsent.status.dismiss) {
          this.ensureFacebookPixelAdded();
        }
      },
      onStatusChange: (status) => {
        if (status === window.cookieconsent.status.dismiss) {
          this.ensureFacebookPixelAdded();
        }
      },
      onRevokeChoice: function () {},
      onNoCookieLaw: function (countryCode, country) {}
    });
  };

  render() {
    const { initApplicationRetries } = this.state;
    const {
      menuIsOpen,
      actions,
      globalSettings,
      modalPageIsOpen,
      initializeApplicationStatus
    } = this.props;

    const {
      lightLogoWhite,
      socialIcons,
      footerAddress,
      footerBottomLinks,
      menu
    } = globalSettings;

    if (initApplicationRetries >= 1) {
      return (
        <FatalError
          showReloadTrigger={
            initApplicationRetries > INIT_APPLICATION_THRESHOLD
          }
          handleClick={() => this.props.history.push('/')}
        />
      );
    }

    if (initializeApplicationStatus !== FULFILLED) return <Loader />;

    const pathname = get(this, 'props.location.pathname');
    const hideMenuAndFooter =
      isModalPage(pathname) ||
      modalPageIsOpen ||
      pathname.includes('order-confirmation') ||
      pathname.includes('footer');
    return (
      <ErrorBoundary flushStateAndReload={() => this.props.history.push('/')}>
        <div className="App relative">
          {globalSettings.siteWideBanner && (
            <Button
              className="SiteWideBanner"
              wrapperClassName="text-center block"
              color="white"
              font="nav"
              to={globalSettings.siteWideBannerLink}
              ariaLabel="site wide banner"
            >
              <p className="w100 label futura my_25 text-center ">
                {globalSettings.siteWideBanner}
              </p>
            </Button>
          )}
          <div className="Main">
            {!hideMenuAndFooter && <Nav />}
            {!hideMenuAndFooter && (
              <Menu
                menuIsOpen={menuIsOpen}
                closeMenu={actions.closeMenu}
                logo={lightLogoWhite}
                menu={menu}
                pathname={pathname}
              />
            )}
            {!menuIsOpen && (
              <Routes
                location={get(this, 'props.location')}
                globalSettings={globalSettings}
              />
            )}
          </div>
        </div>
        {!hideMenuAndFooter && !menuIsOpen && (
          <Footer
            logo={lightLogoWhite}
            address={footerAddress}
            socialIcons={socialIcons}
            footerBottomLinks={footerBottomLinks}
            location={get(this, 'props.location')}
            menu={menu}
          />
        )}
        <FlashMessage />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    initializeApplicationStatus: get(state, 'status.initializeApplication'),
    initializeApplicationError: get(state, 'error.initializeApplication'),
    menuIsOpen: get(state, 'navUI.menuIsOpen', false),
    globalSettings: globalSettings(state),
    modalPageIsOpen: get(state, 'modalPage.modalPageIsOpen'),
    fetchGenericPageStatus: get(state, 'status.fetchGenericPage')
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      initializeApplication,
      closeMenu,
      handleServerErrors
    },
    dispatch
  )
});

App.propTypes = {
  globalSettings: PropTypes.shape({
    footerAddress: PropTypes.string,
    lightLogoWhite: flattenedImageModel.propTypes,
    socialIcons: PropTypes.array
  }),
  actions: PropTypes.shape({
    closeMenu: PropTypes.func,
    initializeApplication: PropTypes.func
  }),
  menuIsOpen: PropTypes.bool
};

App.defaultProps = {
  globalSettings: {
    footerAddress: '',
    lightLogoWhite: flattenedImageModel.default,
    socialIcons: []
  },
  actions: {
    closeMenu: () => {},
    initializeApplication: () => {}
  },
  menuIsOpen: false
};

export { App };
export default connect(mapStateToProps, mapDispatchToProps)(App);
