import getUrlParam from './getUrlParam';

const generateNextPathWithParams = (basePath, params = {}) => {
  // first, check if there is a ref param
  const affiliateId = getUrlParam('ref');
  if (affiliateId) {
    params.ref = affiliateId;
  }

  const queryString = Object.entries(params)
    .map(([key, val]) => {
      return `${key}=${val}`;
    })
    .join('&');

  return queryString ? `${basePath}?${queryString}` : basePath;
};

export default generateNextPathWithParams;
