import gql from 'graphql-tag';
import { productFragment } from './sharedFragments';

/**
 * See more fields that are available on product
 * on the generated Product type definition
 */
export const FETCH_PRODUCT_QUERY = gql`
  query ProductQuery($handle: String!) {
    product(handle: $handle) {
      ...ProductFragment
    }
  }
  ${productFragment}
`;
