import * as React from 'react';
import { useEffect, useState } from 'react';
import get from 'lodash.get';
import { ConnectedProps, connect } from 'react-redux';

import Modal from 'components/Modal';
import { useShopify } from 'lib/Shopify/ShopifyProvider';
import { getPaginatedNodes } from 'utils/graphql';
import { AppState } from 'state/types';
import goBack from 'utils/goBack';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button } from 'components/base';
import CartLineItem from './CartLineItem';
import { formatMoney } from 'utils/shopify';
import { Status } from 'constants/Status';
import ENV from 'config/Environment';

type CartViewProps = RouteComponentProps<{ flow?: string }> &
  ConnectedProps<typeof connector>;

const CartView: React.FC<CartViewProps> = ({
  match: { params },
  routerLocations,
  history
}) => {
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // ensures the cart reloads if a user navigates back to it from another tab
      // e.g. after checking out via shopify
      window.location.reload();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  const { currentCart, cartStatus, addLineItem } = useShopify();
  const [isSimCart] = useState(params.flow && params.flow === 'sim');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isSimCart && !isMounted)
      addLineItem({ merchandiseId: ENV.SHOPIFY_SIM_VARIANT_ID }, true);

    return () => setIsMounted(true);
  }, [isSimCart, isMounted]);

  const lineItems = currentCart ? getPaginatedNodes(currentCart.lines) : [];
  const cartExistsAndHasItems = currentCart && lineItems.length > 0;

  const onClickBack = () => {
    if (params.flow && params.flow === 'phone') {
      history.push('/shop');
      return;
    }
    return goBack({ routerLocations, history });
  };

  return (
    <Modal
      backgroundColor="white"
      closeIconColor="black"
      onGoBack={onClickBack}
    >
      <div className="Cart flex justify-center align-center h100">
        {!cartExistsAndHasItems ? (
          <div className="Cart__empty flex flex-col items-center justify-center">
            <p className="mb2 text-center">Your cart is currently empty.</p>
            <Button
              className="ViewShopButton"
              color="black"
              font="akkurat"
              variant="primary"
              fontColor="white"
              label="View Shop"
              to="/shop"
            />
          </div>
        ) : (
          <div className="flex flex-col w100">
            <div className="Cart__summary">
              <div className="pt1_5 pb1">
                <span className="akkurat title">Order Summary</span>
              </div>
              <div className="Cart__lineItems">
                {lineItems
                  ? lineItems.map((lineItem) => (
                      <CartLineItem
                        lineItem={lineItem}
                        key={lineItem.id}
                        lineItems={lineItems}
                      />
                    ))
                  : null}
              </div>
              <div className="Cart__totals">
                <div>
                  <div className="Cart__totals__line sub-title flex flex-row justify-between my1">
                    <span>Shipping</span>
                    <span>-</span>
                  </div>

                  <div className="Cart__totals__line sub-title flex flex-row justify-between my1">
                    <span>Tax</span>
                    <span>-</span>
                  </div>
                  <div className="Cart__totals__line sub-title flex flex-row justify-between my1">
                    <span>Subtotal</span>
                    <span>
                      {formatMoney(currentCart.cost.subtotalAmount, {
                        includeCents: true
                      })}
                    </span>
                  </div>
                </div>
                <div className="description-sm color-gray mt_25 text-right">
                  *shipping & tax to be calculated
                </div>
              </div>
            </div>
            <div className="Cart__details__links">
              <Link to="/return-policy">Return Policy</Link>
              <Link to="/shipping-details">Shipping Details</Link>
            </div>
            <div className="Cart__ctas">
              <Button
                className="w100 justify-center"
                to="/shop"
                label="View Shop"
                variant="primary"
                color="black--responsive-invert"
                font="akkurat"
              />
              <Button
                className="w100 justify-center"
                to={currentCart.checkoutUrl}
                label="Checkout"
                isDisabled={cartStatus === Status.PENDING}
                variant="primary"
                color="black--responsive-invert"
                fontColor="white"
                font="akkurat"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    routerLocations: get(state, 'routerLocations', [])
  };
};

const connector = connect(mapStateToProps);

export default connector(CartView);
