import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addLineItems,
  createCart,
  fetchCart,
  updateLineItems,
  updateCartAttributes
} from 'state/actions/shopifyCartActions';
import { AppState, Dispatch } from 'state/types';
import { Status } from 'constants/Status';
import {
  Cart,
  CartLineInput,
  CartLineUpdateInput,
  CartUserError
} from 'types/generated-shopify';
import { setCookie, getCookie, removeCookie } from 'utils/storage';
import { getCartAttribute } from 'utils/shopify';

export interface ShopifyContextValue {
  currentCart: Cart | null | void;
  cartStatus: Status;
  cartUserErrors: CartUserError[] | null | void;
  addLineItem: (line: CartLineInput, force?: boolean) => Promise<void>;
  addLineItems: (lines: CartLineInput[]) => Promise<void>;
  updateLineItem: (line: CartLineUpdateInput) => Promise<void>;
  updateLineItems: (lines: CartLineUpdateInput[]) => Promise<void>;
  removeLineItem: (lineItemId: string) => Promise<void>;
  removeLineItems: (lineItemIds: string[]) => Promise<void>;
  goToCheckout: () => void;
}

export const ShopifyContext = React.createContext<
  ShopifyContextValue | undefined
>(undefined);

export const ShopifyConsumer = ShopifyContext.Consumer;

export const useShopify = () => {
  const ctx = React.useContext(ShopifyContext);
  if (!ctx)
    throw new Error('`useShopify` must be used within a ShopifyProvider');
  return ctx;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  children: React.ReactNode;
}

const VIEWER_CART_ID = 'shopify-cart-id';

const setViewerCartCookie = (token: string) => setCookie(VIEWER_CART_ID, token);

const getViewerCartCookie = () => getCookie(VIEWER_CART_ID);

const removeViewerCartCookie = () => removeCookie(VIEWER_CART_ID);

const ShopifyProvider = ({
  children,
  shopifyCart: shopifyCartState,
  actions,
  router
}: Props) => {
  const { createCart, updateCartAttributes, fetchCart } = actions;
  const currentCart = shopifyCartState?.cart;
  const currentCartId = currentCart?.id;
  const cartUserErrors = shopifyCartState?.userErrors;

  const cartId = shopifyCartState.cart?.id;
  const cartStatus = shopifyCartState.status;

  /* Extract referral code from the query params &
   * add it to the cart */
  const refCode = new URLSearchParams(router.location?.search)?.get('ref');
  const referrer = document.referrer || 'no referrer';
  const existingRefCode = currentCart
    ? getCartAttribute(currentCart, 'refCode')
    : null;

  useEffect(() => {
    /* Update the cart's refCode attribute if it the current cart
     * does not have one, or if the existing code does not match.
     *
     * If the cart does not exist, create a fresh one with the refCode
     * attribute.
     */
    if (refCode && (existingRefCode === null || refCode !== existingRefCode)) {
      if (currentCartId) {
        updateCartAttributes(currentCartId, [
          { key: 'refCode', value: refCode },
          { key: 'referrer', value: referrer }
        ]);
      } else {
        createCart({
          attributes: [
            { key: 'refCode', value: refCode },
            { key: 'referrer', value: referrer }
          ]
        });
      }
    } 
  }, [
    existingRefCode,
    referrer,
    refCode,
    currentCartId,
    createCart,
    updateCartAttributes
  ]);

  useEffect(() => {
    /* Fetch a cart by a stored ID on mount */
    const storedCartId = getViewerCartCookie();
    if (!storedCartId || typeof storedCartId !== 'string') return;
    const attemptFetchCart = async () => {
      const result = await fetchCart(storedCartId);
      /* fetchCart is an async action, but typescript
       * does not know that it is. OK to ignore. */
      if (
        // @ts-ignore
        result.action.type === 'FETCH_CART_FULFILLED' &&
        // @ts-ignore
        result.value === null
      ) {
        /* If the query was successful but no cart was fetched,
         * clear the user's stored cart ID. This will happen after
         * a user has completed a checkout and then returned to the
         * website. */
        removeViewerCartCookie();
      }
    };
    if (storedCartId && typeof storedCartId === 'string') {
      attemptFetchCart();
    }
  }, [fetchCart]);

  useEffect(() => {
    if (cartId) {
      setViewerCartCookie(cartId);
    }
  }, [cartId]);

  /* Adds a single line item to the cart */
  const addLineItem = async (line: CartLineInput, force?: boolean) => {
    /* Note: Typescript warns:
     * 'await' has no effect on the type of this expression
     * However, redux-promise-middleware turns them into
     * promises, so it is OK to await them.
     */
    if (!cartId || force) {
      await actions.createCart({ lines: [line] });
    } else {
      await actions.addLineItems(cartId, [line]);
    }
  };

  /* Adds multiple items to the cart */
  const addLineItems = async (lines: CartLineInput[]) => {
    /* Note: Typescript warns:
     * 'await' has no effect on the type of this expression
     * However, redux-promise-middleware turns them into
     * promises, so it is OK to await them.
     */
    if (!cartId) {
      await actions.createCart({ lines });
    } else {
      await actions.addLineItems(cartId, lines);
    }
  };

  /* Updates a single line item */
  const updateLineItem = async (line: CartLineUpdateInput) => {
    if (!cartId) {
      /* TODO: await creating a new cart if none exists */
      throw new Error('no cart yet');
    }
    actions.updateLineItems(cartId, [line]);
  };

  /* Updates multiple line items */
  const updateLineItems = async (lines: CartLineUpdateInput[]) => {
    if (!cartId) {
      /* TODO: await creating a new cart if none exists */
      throw new Error('no cart yet');
    }
    actions.updateLineItems(cartId, lines);
  };

  /* Navigates the user to the Shopify checkout */
  const goToCheckout = () => {
    if (!shopifyCartState.cart) {
      throw new Error('No checkout has been initiated');
    }
    window.location.href = shopifyCartState.cart.checkoutUrl;
  };

  const removeLineItem = async (lineItemId: string) => {
    if (!cartId) {
      /* TODO: await creating a new cart if none exists */
      throw new Error('no cart yet');
    }
    await actions.updateLineItems(cartId, [{ id: lineItemId, quantity: 0 }]);
  };

  const removeLineItems = async (lineItemIds: string[]) => {
    if (!cartId) {
      /* TODO: await creating a new cart if none exists */
      throw new Error('no cart yet');
    }
    const lines = lineItemIds.map((lineItemId) => ({
      id: lineItemId,
      quantity: 0
    }));
    await actions.updateLineItems(cartId, lines);
  };

  const value = {
    cartStatus,
    currentCart,
    cartUserErrors,
    addLineItem,
    addLineItems,
    updateLineItem,
    updateLineItems,
    goToCheckout,
    removeLineItem,
    removeLineItems
  };

  return (
    <ShopifyContext.Provider value={value}>{children}</ShopifyContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => ({
  shopifyCart: state.shopifyCart,
  router: state.router
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      createCart,
      fetchCart,
      addLineItems,
      updateLineItems,
      updateCartAttributes
    },
    dispatch
  )
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ShopifyProvider);
