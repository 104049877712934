import PropTypes from 'prop-types';

const model = {
  propTypes: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string
  }),

  default: {
    url: '',
    title: ''
  }
};

export default model;
