import React from 'react';
import { Button } from 'components/base';

const FatalError = ({ handleClick, showReloadTrigger }) => (
  <div className="fixed z-overlay vh100 vw100">
    <div className="wh100 relative flex flex-col justify-center items-center">
      <h2 className="block-headline italic color-black my1_5">
        Something went wrong
      </h2>
      {!!showReloadTrigger && (
        <div className="my1_5">
          <Button
            ariaLabel="navigate to home"
            variant="primary"
            color="black"
            label="Return home"
            onClick={handleClick}
            to="/"
          />
        </div>
      )}
    </div>
  </div>
);

export default FatalError;
