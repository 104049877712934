import { createSelector } from 'reselect';
import get from 'utils/get';
import flattenImageData from 'utils/flattenImageData';

const article = createSelector(
  (state) => state.article.articlePage,
  (articlePage) => {
    if (!articlePage) return null;

    const slug: string = get(articlePage, 'fields.slug', '');
    const title: string = get(articlePage, 'fields.title', '');
    const createdAt: Date = new Date(
      get(articlePage, 'fields.createdAt', null)
    );
    const highlightImage = flattenImageData(
      get(articlePage, 'fields.highlightImage', {})
    );
    const contentBlocks = get(articlePage, 'fields.contentBlocks', []);
    const tags: string[] = get(articlePage, 'fields.tags', []);
    const id: string = get(articlePage, 'sys.id', '');

    return {
      slug,
      title,
      createdAt,
      highlightImage,
      contentBlocks,
      tags,
      id
    };
  }
);

export default article;
