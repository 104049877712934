import gql from 'graphql-tag';
import { cartFragment } from './sharedFragments';

export const CART_LINE_ITEMS_UPDATE_MUTATION = gql`
  mutation CartLineItemsUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
