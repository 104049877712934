import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { Button } from 'components/base';

type RadioProps = {
  className?: string;
  label?: string;
  checked: boolean;
  variant?: 'primary' | 'secondary';
  isDisabled?: boolean;
  ariaLabel?: string;
  color?: string;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  font?: 'akkurat' | 'nav';
};

const Radio: FC<PropsWithChildren<RadioProps>> = ({
  variant = 'primary',
  color = 'gray',
  className,
  label,
  checked,
  onClick,
  isDisabled,
  children,
  font,
  ariaLabel
}) => {
  const checkedImage = (color: string) => {
    switch (color) {
      case 'gray':
        return '/assets/images/checked-box-icon-gray.svg';
      default:
        return '/assets/images/checked-box-icon-black.svg';
    }
  };

  const uncheckedImage = (color: string) => {
    switch (color) {
      case 'gray':
        return '/assets/images/unchecked-box-icon-gray.svg';
      default:
        return '/assets/images/unchecked-box-icon-black.svg';
    }
  };

  return (
    <Button
      isDisabled={isDisabled}
      aria-label={ariaLabel}
      variant="none"
      onClick={onClick}
      font={font}
      className={cx(
        `Radio Radio--${color} Radio--${variant} relative flex`,
        className
      )}
    >
      <img
        className={cx('Radio__checked absolute', {
          'opacity-0': !checked
        })}
        alt="Check icon"
        src={checkedImage(color)}
      />
      <img
        className={cx('Radio__unchecked absolute', {
          'opacity-0': checked
        })}
        alt="Check icon"
        src={uncheckedImage(color)}
      />
      {label ? <label className="pointer label">{label}</label> : children}
    </Button>
  );
};

export default Radio;
