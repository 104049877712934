import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';
import flattenedImageModel from 'models/flattenedImageModel';

import { Button, Image } from 'components/base';

const Menu = ({ closeMenu, menuIsOpen, logo, menu, pathname }) => {
  return (
    <div
      className={cx(
        'Menu',
        {
          'Menu--active': menuIsOpen
        },
        'fixed z-overlay 100vh 100vw bg-color-gray items-center'
      )}
    >
      <Button
        ariaLabel="close menu"
        onClick={closeMenu}
        className="Menu__close-icon absolute r0 t0 mr1_5 mt1 md:mr3 md:mt2 self-start z2"
      >
        <Image src="/assets/images/close-icon-white.svg" alt="close icon" />
      </Button>
      <div className="w100 vh100 z1 flex flex-col items-center t0">
        <div className="flex flex-col items-center myauto">
          {menu.map((menuField) => {
            const link = menuField.link;
            const name = menuField.name;

            return (
              <Button
                key={name}
                to={link}
                className={cx('Menu__link', {
                  'Menu__link--active': pathname === link
                })}
                onClick={closeMenu}
                label={name}
                aria-label={`navigate to ${name}`}
              />
            );
          })}
        </div>
      </div>
      <Image
        className="Menu__logo absolute b0 l0 m1 md:m2"
        src={contentfulImgUtil(logo.url, '100')}
        alt="Light Logo White"
      />
    </div>
  );
};

Menu.propTypes = {
  closeMenu: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  logo: flattenedImageModel.propTypes,
  menu: PropTypes.array
};

Menu.defaultProps = {
  closeMenu: () => {},
  menuIsOpen: false,
  logo: flattenedImageModel.default,
  menu: []
};

export default Menu;
