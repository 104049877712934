import get from './get';

const flattenImageData = (imageFile) => {
  if (
    typeof get(imageFile, 'title') === 'string' &&
    typeof get(imageFile, 'url') === 'string'
  ) {
    return imageFile;
  }

  return {
    title: get(imageFile, 'fields.title', ''),
    url: get(imageFile, 'fields.file.url', '')
  };
};

export default flattenImageData;
