import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import unhandledPromisesAreOk from 'state/middlewares/unhandledPromisesAreOk';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';
import ENV from 'config/Environment';

import { routerReducer } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import reducers from 'state/reducers';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Only exported for tests
export let middleware = [unhandledPromisesAreOk, thunk, promiseMiddleware()];
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: ENV.SENTRY_DSN });

  middleware = [...middleware, createSentryMiddleware(Sentry, {})];
}

export const history = createBrowserHistory();
export const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  composeEnhancers(applyMiddleware(...middleware))
);
