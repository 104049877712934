import Content from 'lib/Content';
import ContentfulClient from 'lib/Contentful';
import localforage from 'localforage';
import memoryStorageDriver from 'localforage-memoryStorageDriver';

import { fetchGlobalSettings } from 'state/actions/ui/applicationUIActions';

export const INITIALIZE_APPLICATION = 'INITIALIZE_APPLICATION';
export const initializeApplication = () => (dispatch) => {
  return dispatch({
    type: INITIALIZE_APPLICATION,
    payload: new Promise(async (resolve, reject) => {
      try {
        console.log('init app');
        const Contentful = ContentfulClient();
        Content.setRef('contentful', Contentful);

        const presetup = [
          localforage.defineDriver(memoryStorageDriver).then(() => {
            return localforage.config({
              driver: [
                localforage.INDEXEDDB,
                localforage.WEBSQL,
                localforage.LOCALSTORAGE,
                memoryStorageDriver._driver
              ]
            });
          })
        ];

        await Promise.all(presetup);

        await dispatch(fetchGlobalSettings());

        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    })
  });
};
