import gql from 'graphql-tag';
import { imageFragment, moneyV2Fragment } from './sharedFragments';

/**
 * Used to populate the /shop view
 */
export const FETCH_COLLECTIONS_QUERY = gql`
  query CollectionsQuery {
    collections(first: 12) {
      edges {
        node {
          __typename
          id
          handle
          title
          products(first: 50) {
            edges {
              node {
                __typename
                id
                title
                metafields(
                  identifiers: [{ namespace: "descriptors", key: "subtitle" }]
                ) {
                  namespace
                  key
                  value
                }
                featuredImage {
                  ...ImageFragment
                }
                images(first: 2) {
                  edges {
                    node {
                      ...ImageFragment
                    }
                  }
                }
                handle
                availableForSale
                compareAtPriceRange {
                  __typename
                  minVariantPrice {
                    ...MoneyV2Fragment
                  }
                  maxVariantPrice {
                    ...MoneyV2Fragment
                  }
                }
                priceRange {
                  __typename
                  minVariantPrice {
                    ...MoneyV2Fragment
                  }
                  maxVariantPrice {
                    ...MoneyV2Fragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${imageFragment}
  ${moneyV2Fragment}
`;
