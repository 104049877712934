import * as Sentry from '@sentry/browser';

function isPromise(value) {
  if (value !== null && typeof value === 'object') {
    return value && typeof value.then === 'function';
  }
  return false;
}

const ActionWhitelist = [];

const middleware = (store) => (next) => (action) => {
  if (!isPromise(action.payload)) return next(action);

  const promise = next(action);

  promise.catch((error) => {
    if (ActionWhitelist.includes(action.type)) return error;

    if (process.env.NODE_ENV === 'development') {
      console.warn(`Promise Rejected: ${action.type}`, error);
    }

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtra('reduxAction', action.type);
        scope.setExtra('reduxState', store.getState());
        Sentry.captureException(error);
      });
    }
    return error;
  });

  return promise;
};

export default middleware;
