import Content from 'lib/Content';

export const FETCH_MODAL_PAGE = 'FETCH_MODAL_PAGE';
export const fetchModalPage = (path) => (dispatch) => {
  return dispatch({
    type: FETCH_MODAL_PAGE,
    payload: Content.getEntries({
      content_type: 'popoverGenericPage',
      'fields.slug': path,
      include: 4
    })
  });
};

export const OPEN_MODAL_PAGE = 'OPEN_MODAL_PAGE';
export const openModalPage = () => ({
  type: OPEN_MODAL_PAGE,
  payload: true
});

export const CLOSE_MODAL_PAGE = 'CLOSE_MODAL_PAGE';
export const closeModalPage = () => ({
  type: CLOSE_MODAL_PAGE,
  payload: false
});
