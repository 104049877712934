import { FETCH_COMPATIBILITY_CHECK } from 'state/actions/compatibilityCheckActions';

const initialState = {
  compatibilityCheck: {}
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case `${FETCH_COMPATIBILITY_CHECK}_FULFILLED`:
      return {
        ...state,
        compatibilityCheck: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
