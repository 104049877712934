import gql from 'graphql-tag';

export const moneyV2Fragment = gql`
  fragment MoneyV2Fragment on MoneyV2 {
    __typename
    amount
    currencyCode
  }
`;

export const imageFragment = gql`
  fragment ImageFragment on Image {
    __typename
    id
    altText
    height
    width
    url
  }
`;

/**
 * See more details about each of these fields in the
 * Cart definition in types/generated-shopify.ts
 *
 * There are more fields available, such as 'buyerIdentity',
 * 'discountCodes' and 'discountAllocations' that we may
 * need to implement in the future.
 */
export const cartFragment = gql`
  fragment CartFragment on Cart {
    __typename
    id
    checkoutUrl
    note
    totalQuantity
    attributes {
      __typename
      key
      value
    }
    lines(first: 100) {
      __typename
      edges {
        __typename
        cursor
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              __typename
              id
              availableForSale
              quantityAvailable
              title
              product {
                id
                title
                handle
                metafields(
                  identifiers: [{ namespace: "descriptors", key: "subtitle" }]
                ) {
                  namespace
                  key
                  value
                }
              }
              metafields(
                identifiers: [
                  { namespace: "settings", key: "accepting_preorders" }
                  { namespace: "settings", key: "estimated_ship_date" }
                ]
              ) {
                namespace
                key
                value
              }
            }
          }
          attributes {
            __typename
            key
            value
          }
          cost {
            __typename
            amountPerQuantity {
              ...MoneyV2Fragment
            }
            subtotalAmount {
              ...MoneyV2Fragment
            }
            totalAmount {
              ...MoneyV2Fragment
            }
          }
        }
      }
      pageInfo {
        __typename
        hasNextPage
        hasPreviousPage
      }
    }
    cost {
      checkoutChargeAmount {
        ...MoneyV2Fragment
      }
      subtotalAmount {
        ...MoneyV2Fragment
      }
      subtotalAmountEstimated
      totalTaxAmount {
        ...MoneyV2Fragment
      }
      totalTaxAmountEstimated
      totalAmount {
        ...MoneyV2Fragment
      }
      totalAmountEstimated
    }
  }
  ${moneyV2Fragment}
`;

export const productFragment = gql`
  fragment ProductFragment on Product {
    __typename
    id
    title
    availableForSale
    description
    descriptionHtml
    featuredImage {
      ...ImageFragment
    }
    handle
    tags
    metafields(identifiers: [{ namespace: "descriptors", key: "subtitle" }]) {
      namespace
      key
      value
    }
    variants(first: 12) {
      edges {
        node {
          __typename
          id
          title
          availableForSale
          quantityAvailable
          image {
            ...ImageFragment
          }
          price {
            ...MoneyV2Fragment
          }
          compareAtPrice {
            ...MoneyV2Fragment
          }
          product {
            id
            title
            handle
          }
          metafields(
            identifiers: [
              { namespace: "descriptors", key: "customsnote" }
              { namespace: "settings", key: "accepting_preorders" }
              { namespace: "settings", key: "estimated_ship_date" }
            ]
          ) {
            namespace
            key
            value
          }

          selectedOptions {
            __typename
            name
            value
          }
        }
      }
    }
    options {
      id
      name
      values
    }
    priceRange {
      __typename
      maxVariantPrice {
        ...MoneyV2Fragment
      }
      minVariantPrice {
        ...MoneyV2Fragment
      }
    }
    images(first: 100) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
  }
  ${moneyV2Fragment}
  ${imageFragment}
`;
