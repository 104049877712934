import { Button, Image } from 'components/base';
import * as React from 'react';
import slugify from 'utils/slugify';

interface ModalProps {
  children: React.ReactNode;
  backgroundColor: string;
  closeIconColor: string;
  onGoBack: () => void;
}

const Modal: React.FC<ModalProps> = ({
  backgroundColor = 'white',
  closeIconColor,
  onGoBack,
  children
}) => {
  const closeIcon =
    slugify(closeIconColor) === 'white'
      ? '/assets/images/close-icon-white.svg'
      : '/assets/images/close-icon-black.svg';

  return (
    <div
      style={{ backgroundColor }}
      className="ModalPageView relative z-overlay"
    >
      <div className="relative wh100 pt3 md:pt6 pb3 md:py6 flex flex-col justify-between">
        <Button
          ariaLabel="navigate to previous page"
          onClick={onGoBack}
          className="ModalPageView__close-icon absolute l0 t0 mt3 ml1_5 md:ml3 z1 fixed"
        >
          <Image src={closeIcon} alt="navigate to previous page" />
        </Button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
