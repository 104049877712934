import * as React from 'react';
import cx from 'classnames';
import { useState } from 'react';
import { Status } from 'constants/Status';
import { useShopify } from 'lib/Shopify';
import { BaseCartLine } from 'types/generated-shopify';
import { lineItemIsNaPhone, findSimLineItem } from './shared';

interface CartLineItemQuantityProps {
  lineItem: BaseCartLine;
  lineItems: BaseCartLine[];
}

const CartLineItemQuantity: React.FC<CartLineItemQuantityProps> = ({
  lineItem,
  lineItems
}) => {
  const { cartStatus, updateLineItems } = useShopify();
  const [isNAPhone] = useState(lineItemIsNaPhone(lineItem));

  const decrementQuantity = () => {
    const linesToUpdate = [
      {
        id: lineItem.id,
        quantity: Math.max(lineItem.quantity - 1, 0)
      }
    ];

    if (isNAPhone) {
      const simLineItem = findSimLineItem(lineItems);
      if (simLineItem)
        linesToUpdate.push({
          id: simLineItem.id,
          quantity: Math.max(simLineItem.quantity - 1, 0)
        });
    }

    updateLineItems(linesToUpdate);
  };

  const incrementQuantity = () => {
    const linesToUpdate = [
      {
        id: lineItem.id,
        quantity: lineItem.quantity + 1
      }
    ];

    if (isNAPhone) {
      const simLineItem = findSimLineItem(lineItems);
      if (simLineItem)
        linesToUpdate.push({
          id: simLineItem.id,
          quantity: simLineItem.quantity + 1
        });
    }

    updateLineItems(linesToUpdate);
  };

  const isDisabled = cartStatus === Status.PENDING;
  return (
    <div
      className={cx(
        'Cart__lineItemQuantity',
        isDisabled && 'Cart__lineItemQuantity--disabled'
      )}
    >
      <button disabled={isDisabled} type="button" onClick={decrementQuantity}>
        -
      </button>
      <div className="Cart__lineItemQuantity__quantity">
        {lineItem.quantity}
      </div>

      <button disabled={isDisabled} type="button" onClick={incrementQuantity}>
        +
      </button>
    </div>
  );
};

export default CartLineItemQuantity;
