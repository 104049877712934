import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCollections } from 'state/actions/shopifyCatalogueActions';
import { AppState, Dispatch } from 'state/types';

class ShopContainer extends ContainerBase {
  // @ts-ignore
  view = import('views/ShopView');

  model = async () => {
    const collections = await this.props.actions
      .fetchCollections()
      .then(({ value }) => value);
    return { collections };
  };
}

const mapStateToProps = (state: AppState) => ({
  collections: state.shopifyCatalogue?.collections
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchCollections }, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-ignore
export default connector(ShopContainer);
