import { createSelector } from 'reselect';
import get from 'utils/get';
import * as Types from 'lib/types';
import slugify from 'utils/slugify';

const groupArticlesByTag = (
  articles: Types.Article[]
): Record<string, Types.Article[]> => {
  const grouped: Record<string, Types.Article[]> = {};

  articles.forEach((article) => {
    article.tags.forEach((tag) => {
      const sluggifiedTag = slugify(tag);
      if (!grouped[sluggifiedTag]) {
        grouped[sluggifiedTag] = [];
      }
      grouped[sluggifiedTag].push(article);
    });
  });

  return grouped;
};

const articlesBytag = createSelector(
  (state) => state.blog,
  (data) => {
    const articles = get(data, 'allArticles', []);
    if (!articles) return [];

    const groupedArticles = groupArticlesByTag(articles);

    return groupedArticles;
  }
);

export default articlesBytag;
