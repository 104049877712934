import 'custom-event-polyfill';
import XhrCache from '@spirit-fish/xhr-cache';

import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { Route } from 'react-router-dom';
import ShopifyProvider from './lib/Shopify/ShopifyProvider';

import { store, history } from 'store';

import App from 'App';

window.SPIRIT_FISH = false;
XhrCache.setup({
  shouldCache: (url) => {
    if (url.includes('api/carts/credit_checks')) return false;

    return (
      url.includes('vimeo.com') ||
      url.includes('lightphonecloud.com') ||
      url.includes('contentful.com')
    );
  },
  shouldLog: (url) => {
    return process.env.NODE_ENV !== 'production';
  },
  didError: (url, e, stage) => {
    Sentry.configureScope((scope) => {
      scope.setTag('xhr_cache_stage', stage);
      scope.setExtra('url', url);
      Sentry.captureException(e);
    });
  }
});

const rootElement = document.getElementById('root');
render(
  <Provider store={store}>
    <ShopifyProvider>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </ShopifyProvider>
  </Provider>,
  rootElement
);
