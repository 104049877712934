const constant = [
  '/compatibility-checker',
  '/products',
  '/products/pricing',
  '/products/pricing/',
  '/products/plans',
  '/products/plans/',
  '/products/accessories',
  '/products/accessories/',
  '/products/offsets',
  '/products/offsets/',
  '/checkout'
];

export default constant;
