import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import get from 'utils/get';
import { fetchArticle } from 'state/actions/articlePageActions';
import globalSettingsSelector from 'state/selectors/globalSettings';
import articleSelector from 'state/selectors/article';
import { AppState } from 'state/types';
import { Status } from 'constants/Status';

interface StateProps {
  globalSettings: ReturnType<typeof globalSettingsSelector>;
  article: ReturnType<typeof articleSelector>;
  fetchArticleStatus: Status;
}

const mapStateToProps = (state: AppState): StateProps => ({
  globalSettings: globalSettingsSelector(state),
  article: articleSelector(state),
  fetchArticleStatus: state.status.fetchArticlePage
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchArticle: bindActionCreators(fetchArticle, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ArticleContainer extends ContainerBase {
  view: any = import('views/ArticleView');

  model = async () => {
    const { fetchArticle } = this.props;
    const { match } = this.props;
    const slug = get(match, 'params.articleSlug', '');

    if (!slug) {
      return {
        article: null
      };
    }

    try {
      const article = await fetchArticle(slug);
      return {
        article: article
      };
    } catch (error) {
      return {
        article: null
      };
    }
  };
}

export default connector(ArticleContainer);
