import DOMPurify from 'dompurify';
import { history } from 'store';

import {
  Cart,
  MoneyV2,
  Product,
  ProductVariant
} from 'types/generated-shopify';
import filterFalsy from './filterFalsy';
import isDefined from './isDefined';
import { getPaginatedNodes } from './graphql';
import {
  LIGHT_PHONE_BLACK_HANDLE,
  LIGHT_PHONE_LIGHT_HANDLE
} from 'constants/Shopify';

/**
 * Some components require custom logic when dealing with
 * a Light Phone product. This utility can be used to
 * determine if a given product is a Light Phone.
 */
export const productIsLightPhone = (product: Product) =>
  product.handle === LIGHT_PHONE_LIGHT_HANDLE ||
  product.handle === LIGHT_PHONE_BLACK_HANDLE;

/**
 * Convert shopify's MoneyV2 object into a
 * price string
 */

type FormatMoneyOptions = {
  includeCents: boolean;
};

export const formatMoney = (
  money: MoneyV2,
  options?: FormatMoneyOptions,
  isPreorder?: boolean
): string => {
  const { amount, currencyCode } = money;
  const includeCents =
    options && isDefined(options.includeCents) ? options.includeCents : false;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2
  });
  const formatted = formatter.format(parseFloat(amount));
  return includeCents ? formatted : formatted.replace('.00', '');
};

/* Converts a shopify "money" string to an integer of the amount in cents */
/* Example: '{ amount: '299.0' }' -> 29900 */
export const moneyV2toCents = (money: MoneyV2) =>
  parseFloat(money.amount) * 100;

/**
 * Metafields
 */
const getMetaField = (
  item: Product | ProductVariant,
  namespace: string,
  key: string
): string | undefined =>
  filterFalsy(item.metafields).find(
    (metafield) => metafield.namespace === namespace && metafield.key === key
  )?.value;

/* Get a product's subtitle metafield */
export const getSubtitle = (product: Product) =>
  getMetaField(product, 'descriptors', 'subtitle');
/* Get a variant's customsNote metafield */
export const getCustomsNote = (variant: ProductVariant) =>
  getMetaField(variant, 'descriptors', 'customsnote');
/* Get a variant's accepting_preorders metafield */
export const getPreorderStatus = (variant: ProductVariant) =>
  getMetaField(variant, 'settings', 'accepting_preorders');
/* Get a variant's estimated_ship_date metafield */
export const getEstimatedShipDate = (variant: ProductVariant) =>
  getMetaField(variant, 'settings', 'estimated_ship_date');

/**
 * Cart Attributes
 */
export const getCartAttribute = (cart: Cart, key: string): string | null =>
  cart.attributes?.find((a) => a.key === key)?.value || null;
/**
 * Gets the first variant of a product
 */

export const getFirstVariant = (product: Product): ProductVariant =>
  getPaginatedNodes(product.variants)[0];

/**
 * Sanitize HTML from Shopify descriptions
 */
export const sanitizeHtmlDescription = (text: string) =>
  DOMPurify.sanitize(text, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
    ALLOWED_ATTR: ['href']
  });

/**
 * Sanitize links from Shopify descriptions so they 'push' to
 * to Reacts history object rather than 'pop' out of it.
 */
export const sanitizeShopifyLinks = (event) => {
  const targetLink = event.target?.closest('a');
  if (!targetLink) return;
  const slug = targetLink.pathname;
  event.preventDefault();
  history.push(`${slug}`);
};
