import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';

import globalSettings from 'state/selectors/globalSettings';

class FooterPageContainer extends ContainerBase {
  view = import('views/FooterPageView');
}

const mapStateToProps = (state) => {
  return {
    globalSettings: globalSettings(state)
  };
};

export default connect(mapStateToProps)(FooterPageContainer);
