import ENV from 'config/Environment';
import { createClient } from 'contentful';

const client = () => {
  return createClient({
    space: ENV.CONTENTFUL_SPACE_ID,
    accessToken: ENV.CONTENTFUL_ACCESS_TOKEN,
    host: ENV.CONTENTFUL_HOST
  });
};

export default client;
