import * as Sentry from '@sentry/browser';
import get from 'utils/get';

import { initializeApplication } from 'state/actions/applicationActions';

export const HANDLE_SERVER_ERRORS = 'HANDLE_SERVER_ERRORS';
export const handleServerErrors =
  (error, triggerInitializeApplication = false) =>
  (dispatch) => {
    return {
      type: HANDLE_SERVER_ERRORS,
      payload: new Promise((resolve) => {
        switch (get(error, 'status')) {
          // Unauthorized
          case 401:
            return triggerInitializeApplication
              ? dispatch(initializeApplication()).then(resolve)
              : Promise.resolve();
          default:
            Sentry.withScope((scope) => {
              scope.setExtra('initializeApplication');
              Sentry.captureException(error);
            });

            resolve();
            break;
        }
      })
    };
  };
