import Content from 'lib/Content';

export const FETCH_ALL_ARTICLES = 'FETCH_ALL_ARTICLES';

export const fetchAllArticles = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_ARTICLES,
    payload: Content.getEntries({
      content_type: 'article',
      order: '-fields.createdAt'
    })
  });
};
