import Content from 'lib/Content';

export const FETCH_GLOBAL_SETTINGS = 'FETCH_GLOBAL_SETTINGS';
export const fetchGlobalSettings = (payload) => (dispatch) => {
  return dispatch({
    type: FETCH_GLOBAL_SETTINGS,
    payload: Content.getEntries({
      content_type: 'globalSettings',
      include: 4
    })
  });
};
