import get from 'utils/get';
import { FETCH_ARTICLE_PAGE } from 'state/actions/articlePageActions';
import * as Types from 'lib/types';

interface ArticleState {
  articlePage: Types.Article | null;
}

const initialState: ArticleState = {
  articlePage: null
};

type FetchArticleAction = {
  type: string;
  payload: {
    items: Types.Article[];
  };
};

const reducer = (
  state = initialState,
  action: FetchArticleAction
): ArticleState => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ARTICLE_PAGE}_FULFILLED`:
      return {
        ...state,
        articlePage: get(payload, 'items[0]', null)
      };
    case `${FETCH_ARTICLE_PAGE}_REJECTED`:
      return {
        ...state,
        articlePage: null
      };
    default:
      return state;
  }
};

export default reducer;
