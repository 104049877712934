import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TextField = ({
  id,
  className,
  label,
  showLabel,
  name,
  error,
  onBlur,
  onChange,
  pattern,
  placeholder,
  type,
  required,
  value,
  variant,
  color,
  fullWidth,
  min,
  max,
  inputRef
}) => {
  const classes = cx('TextField', className, {
    'TextField--error': error,
    w100: fullWidth
  });

  const _id = id || name;

  return (
    <div
      className={cx(
        classes,
        `TextField--style-${variant} TextField--color-${color}`,
        {
          'TextField--textarea': type === 'textarea'
        }
      )}
    >
      {showLabel ? <label htmlFor={_id}>{label}</label> : null}
      {type === 'textarea' ? (
        <textarea
          aria-label={label}
          id={_id}
          name={name}
          onBlur={(e) => onBlur(e.target.value)}
          onChange={(e) => onChange(e.target.value)}
          pattern={pattern}
          placeholder={placeholder}
          required={required ? 'required' : false}
          value={value}
        />
      ) : (
        <input
          ref={inputRef}
          aria-label={label}
          id={_id}
          name={name}
          onBlur={(e) => onBlur(e.target.value)}
          onChange={(e) => onChange(e.target.value)}
          pattern={pattern}
          placeholder={placeholder}
          type={type}
          min={min}
          max={max}
          required={required ? 'required' : false}
          value={value}
        />
      )}
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pattern: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp)
  ]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool
};

TextField.defaultProps = {
  className: '',
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  required: false,
  type: 'text',
  name: '',
  min: null,
  max: null,
  variant: 'border-bottom',
  color: 'white',
  showLabel: false,
  label: ''
};

export default TextField;
