/**
 * Get the last item from an array
 */

export const last = <T>(arr: T[]): T => arr[arr.length - 1];

/**
 * Makes items in an array unqiue
 */
export const unique = <T>(arr: T[]): T[] => Array.from(new Set(arr));

/**
 * Puts a single item into an array, or returns
 * an array as-is
 **/

export const arrayify = <T>(value: T | T[]): T[] =>
  Array.isArray(value) ? value : [value];
