import {
  FETCH_MODAL_PAGE,
  OPEN_MODAL_PAGE,
  CLOSE_MODAL_PAGE
} from 'state/actions/modalPageActions';

const initialState = {
  modalPage: {},
  modalPageIsOpen: false
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case `${FETCH_MODAL_PAGE}_FULFILLED`:
      return {
        ...state,
        modalPage: action.payload
      };
    case OPEN_MODAL_PAGE:
    case CLOSE_MODAL_PAGE:
      return {
        ...state,
        modalPageIsOpen: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
