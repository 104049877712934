type Edge<T> = {
  cursor: string;
  node: T;
};
export type Paginated<T> = {
  edges: Edge<T>[];
};

/**
 * Gets items from a paginated GraphQL response as an array
 */
export const getPaginatedNodes = <T>(connection: Paginated<T>): T[] =>
  connection.edges.map((edge) => edge.node);
