import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';

const FlashMessage = () => (
  <ToastContainer
    position="top-center"
    hideProgressBar
    closeOnClick
    draggable={false}
    autoClose={5000}
  />
);

export default FlashMessage;
