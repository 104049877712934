import gql from 'graphql-tag';
import { cartFragment } from './sharedFragments';

export const CART_CREATE_MUTATION = gql`
  mutation CartCreate($input: CartInput) {
    cartCreate(input: $input) {
      userErrors {
        __typename
        code
        field
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
