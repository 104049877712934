import { INITIALIZE_APPLICATION } from 'state/actions/applicationActions';

const initialState = {
  initializeApplication: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${INITIALIZE_APPLICATION}_PENDING`:
      return { ...state, initializeApplication: null };
    case `${INITIALIZE_APPLICATION}_REJECTED`:
      return { ...state, initializeApplication: payload };

    default:
      return state;
  }
};

export default reducer;
