import React, { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cx from 'classnames';

import { openMenu } from 'state/actions/ui/navUIActions';
import { Button } from 'components/base';
import { AppState, Dispatch } from 'state/types';
import { Route, Switch } from 'react-router-dom';
import { openCart } from 'state/actions/shopifyCartActions';
import get from 'lodash.get';

/**
 * Utils
 */
type NavSettings = {
  navColor: string;
  buyButtonIsActive: boolean;
  menuButtonIsActive: boolean;
};

/* Settings from Contentful */
type PageNavSettings = {
  slug: string;
  navColor?: string;
  buyButtonIsActive?: boolean;
  menuButtonIsActive?: boolean;
};

const getNavSettings = (
  pathname: string,
  pageNavSettings?: PageNavSettings
): NavSettings => {
  /* If the current path matches the page's slug, respect its settings */
  if (pageNavSettings?.slug === pathname) {
    return {
      /* defaults */
      navColor: 'white',
      buyButtonIsActive: true,
      menuButtonIsActive: true,
      /* override with page settings */
      ...pageNavSettings
    };
  }
  /* Otherwise, return defaults */
  return {
    navColor: 'black',
    buyButtonIsActive: true,
    menuButtonIsActive: true
  };
  //
};

/**
 * Nav
 */
type PropsFromRedux = ConnectedProps<typeof connector>;

type NavProps = PropsFromRedux;

const Nav: FC<NavProps> = ({
  actions,
  location,
  genericPageSettings,
  modalPageIsOpen
}) => {
  const { openMenu } = actions;
  const { key, pathname } = location;
  const {
    navColor: navColorValue,
    buyButtonIsActive,
    menuButtonIsActive
  } = getNavSettings(pathname, genericPageSettings);
  /* Color values from Contentful are uppercased, convert the color here to handle it */
  const navColor = navColorValue.toLowerCase();
  if (modalPageIsOpen) return null;
  if (pathname.startsWith('/shop/products/light-phone-ii')) return null;
  return (
    <div key={key} className="Nav fixed w100 z-nav events-none">
      <div
        className={cx(
          'sticky t0 z-nav w100 py_5 px1 md:py2 md:px3 flex flex-row items-center justify-between',
          {
            'Nav--hide-buy': !buyButtonIsActive
          }
        )}
      >
        <div className="z-nav">
          <Switch>
            <Route
              path={['/shop', '/cart']}
              /* On any of the /shop routes, the first
               * menu link should be to open the cart. */
              render={() => (
                <Button
                  ariaLabel="open cart"
                  label="cart"
                  to="/cart"
                  fontColor={navColor}
                  font="nav"
                  className="events-all z-overlay"
                />
              )}
            />
            <Route
              /* On all other routes, the first menu link
               * should navigate to the /shop page */
              render={() => (
                <Button
                  ariaLabel="navigate to products"
                  label="shop"
                  to="/shop"
                  fontColor={navColor}
                  font="nav"
                  className="events-all z-overlay"
                />
              )}
            />
          </Switch>
        </div>
        {menuButtonIsActive && (
          <Button
            ariaLabel="open menu"
            label="menu"
            onClick={openMenu}
            fontColor={navColor}
            font="nav"
            className="events-all z-nav"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  genericPageSettings: get(state, 'genericPage.genericPage.items[0].fields'),
  modalPageIsOpen: state.modalPage?.modalPageIsOpen
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      {
        openMenu,
        openCart
      },
      dispatch
    )
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Nav);
