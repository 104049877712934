import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Flickity from 'flickity';
import cx from 'classnames';
import get from 'utils/get';
import flattenImageData from 'utils/flattenImageData';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import { Image } from 'components/base';

class ImageCarousel extends Component {
  flickity = null;

  componentDidMount() {
    this.initializeCarousel();
  }

  initializeCarousel() {
    this.flickity = new Flickity(this.wrapper, {
      prevNextButtons: false,
      pageDots: false,
      resize: true,
      arrowShape: {
        x0: 10,
        x1: 55,
        y1: 50,
        x2: 67,
        y2: 40,
        x3: 30
      },
      on: {
        change: (index) => {
          this.props.getActiveImageIndex(index);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeImageIndex !== this.props.activeImageIndex) {
      this.flickity.select(this.props.activeImageIndex);
    }
  }

  render() {
    const { images, fullImage, color, imageClassName, className, children } =
      this.props;
    const imageSize = fullImage ? '1600' : '500';

    return (
      <div
        className={cx(
          `ImageCarousel ImageCarousel--button-${color}`,
          className,
          {
            'ImageCarousel--full': fullImage
          }
        )}
        ref={(node) => (this.wrapper = node)}
      >
        {children
          ? children
          : images.map((image, i) => {
              const flattendImage = flattenImageData(image);

              return (
                <div
                  key={get(image, 'sys.id', i)}
                  className={cx('ImageCarousel__image wh100', {
                    'ImageCarousel__image--full': fullImage,
                    'flex items-start justify-center': !fullImage
                  })}
                >
                  <Image
                    className={cx('wh100', imageClassName, {
                      'fit-contain': !fullImage,
                      'fit-cover': fullImage
                    })}
                    src={contentfulImgUtil(flattendImage.url, imageSize)}
                    alt={flattendImage.title}
                  />
                </div>
              );
            })}
      </div>
    );
  }
}

ImageCarousel.propTypes = {
  images: PropTypes.array,
  fullImage: PropTypes.bool,
  color: PropTypes.string,
  imageClassName: PropTypes.string,
  className: PropTypes.string,
  activeImageIndex: PropTypes.number,
  getActiveImageIndex: PropTypes.func
};

ImageCarousel.defaultProps = {
  images: [],
  fullImage: false,
  color: 'White',
  imageClassName: '',
  className: '',
  activeImageIndex: 0,
  getActiveImageIndex: (f) => f
};

export default ImageCarousel;
