import { BaseCartLine } from 'types/generated-shopify';
import ENV from 'config/Environment';

/**
 * Shared functions
 */

export const lineItemIsNaPhone = (lineItem: BaseCartLine): boolean => {
  return (
    lineItem.merchandise.title === 'North America' &&
    lineItem.merchandise.product.handle.startsWith('light-phone-ii-')
  );
};

export const findSimLineItem = (
  lineItems: BaseCartLine[]
): BaseCartLine | undefined => {
  return lineItems.find((lineItem) => {
    return lineItem.merchandise.id == ENV.SHOPIFY_SIM_VARIANT_ID;
  });
};
