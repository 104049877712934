import Content from 'lib/Content';

export const FETCH_ARTICLE_PAGE = 'FETCH_ARTICLE_PAGE';
export const fetchArticle = (path) => (dispatch) => {
  return dispatch({
    type: FETCH_ARTICLE_PAGE,
    payload: Content.getEntries({
      content_type: 'article',
      'fields.slug': path
    })
  });
};
