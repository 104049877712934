import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import globalSettingsSelector from 'state/selectors/globalSettings';
import { fetchPhoneFlowProducts } from 'state/actions/shopifyCatalogueActions';
import { AppState, Dispatch } from 'state/types';
import { PhoneFlowProducts } from 'state/reducers/shopifyCatalogue';

class LightPhoneFlowIIIContainer extends ContainerBase {
  // @ts-ignore
  view = import('views/LightPhoneIIIFlow');

  model = async () => {
    const existingProducts =
      this.props.lightPhoneFlowProducts; /* may be null */

    if (existingProducts) {
      return {
        phoneFlowProducts: existingProducts
      };
    }

    let phoneFlowProducts: PhoneFlowProducts | null = null;

    try {
      /* Return existing products if available, otherwise fetch them */
      phoneFlowProducts = await this.props.actions
        .fetchPhoneFlowProducts()
        .then(({ value }) => value);

      return { phoneFlowProducts };
    } catch (error) {
      console.log(error);
    }

    return {
      phoneFlowProducts
    };
  };
}

const mapStateToProps = (state: AppState) => ({
  lightPhoneFlowProducts: state.shopifyCatalogue.phoneFlowProducts,
  globalSiteSettings: globalSettingsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchPhoneFlowProducts }, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-ignore
export default connector(LightPhoneFlowIIIContainer);
