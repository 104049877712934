import gql from 'graphql-tag';
import { cartFragment } from './sharedFragments';

export const CART_FETCH_QUERY = gql`
  query CartQuery($id: ID!) {
    cart(id: $id) {
      ...CartFragment
    }
  }
  ${cartFragment}
`;
