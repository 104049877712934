import gql from 'graphql-tag';
import { productFragment } from './sharedFragments';
import {
  LIGHT_PHONE_BLACK_HANDLE,
  LIGHT_PHONE_LIGHT_HANDLE,
  LIGHT_PHONE_III_HANDLE,
  PROTECTIVE_CASE_BLACK_HANDLE,
  PROTECTIVE_CASE_LIGHT_HANDLE
} from 'constants/Shopify';

export const FETCH_PHONE_FLOW_PRODUCTS_QUERY = gql`
  query PhoneFlowProductsQuery {
    lightPhoneLight: product(handle: "${LIGHT_PHONE_LIGHT_HANDLE}") {
      ...ProductFragment
    }

    lightPhoneDark: product(handle: "${LIGHT_PHONE_BLACK_HANDLE}") {
      ...ProductFragment
    }

    lightPhoneIII: product(handle: "${LIGHT_PHONE_III_HANDLE}") {
      ...ProductFragment
    }

    screenProtector: product(handle: "screen-protector") {
      ...ProductFragment
    }

    protectiveCaseBlack: product(handle: "${PROTECTIVE_CASE_BLACK_HANDLE}") {
      ...ProductFragment
    }

    protectiveCaseLight: product(handle: "${PROTECTIVE_CASE_LIGHT_HANDLE}") {
      ...ProductFragment
    }

    carbonOffset: product(handle: "carbon-offset") {
      ...ProductFragment
    }

    lightUsSim: product(handle: "light-us-sim-card") {
      ...ProductFragment
    }
  }
  ${productFragment}
`;
