import { createSelector } from 'reselect';
import get from 'utils/get';
import flattenFragment from 'utils/flattenFragment';
import flattenImageData from 'utils/flattenImageData';

export default createSelector(
  (state) => get(state, 'applicationUI.globalSettings', {}),
  (contentfulResponse) => {
    const globalSettingsFields = get(contentfulResponse, 'items[0].fields', {});
    const welcomeMessage = get(globalSettingsFields, 'welcomeMessage', '');
    const siteWideBanner = get(globalSettingsFields, 'siteWideBanner', '');
    const siteWideBannerLink = get(
      globalSettingsFields,
      'siteWideBannerLink',
      ''
    );
    const lightLogoWhite = flattenImageData(
      get(globalSettingsFields, 'lightLogoWhite', {})
    );
    const lightLogoBlack = flattenImageData(
      get(globalSettingsFields, 'lightLogoBlack', {})
    );
    const footerAddress = get(globalSettingsFields, 'footerAddress', '');
    const socialIcons = flattenFragment(
      get(globalSettingsFields, 'socialIcons', {})
    );
    const modalPageBottomLogo = flattenImageData(
      get(globalSettingsFields, 'popoverPageBottomLogo', {})
    );
    const errorPageBackgroundImage = flattenImageData(
      get(globalSettingsFields, 'errorPageBackgroundImage', {})
    );
    const footerBottomLinks = flattenFragment(
      get(globalSettingsFields, 'footerBottomLinks', {})
    );
    const orderConfirmationPageBackgroundImage = flattenImageData(
      get(globalSettingsFields, 'orderConfirmationPageBackgroundImage', {})
    );
    const menu = flattenFragment(get(globalSettingsFields, 'menu', {}));
    const activatePreOrderButton = get(
      globalSettingsFields,
      'activatePreOrderButton',
      false
    );
    const tagOrderOnBlogNavigation = get(
      globalSettingsFields,
      'tagOrderOnBlogNavigation',
      []
    );
    const selectedArticles = get(globalSettingsFields, 'selectedArticles', []);

    const formattedSeletedArticles = selectedArticles.map((article) => {
      const slug = get(article, 'fields.slug', '');
      const title = get(article, 'fields.title', '');
      const createdAt = new Date(get(article, 'fields.createdAt', null));
      const contentBlocks = get(article, 'fields.contentBlocks', []);
      const highlightImage = flattenImageData(
        get(article, 'fields.highlightImage', {})
      );
      const tags = get(article, 'fields.tags', []);
      const id = get(article, 'sys.id', '');
      return {
        slug,
        title,
        createdAt,
        contentBlocks,
        highlightImage,
        tags,
        id
      };
    });

    const lightIIFAQ = get(globalSettingsFields, 'lightIiFaq', []);

    const lightIIIFAQ = get(globalSettingsFields, 'lightIiiFaq', []);

    return {
      welcomeMessage,
      siteWideBanner,
      siteWideBannerLink,
      lightLogoWhite,
      lightLogoBlack,
      footerAddress,
      socialIcons,
      modalPageBottomLogo,
      errorPageBackgroundImage,
      footerBottomLinks,
      orderConfirmationPageBackgroundImage,
      menu,
      activatePreOrderButton,
      tagOrderOnBlogNavigation,
      selectedArticles: formattedSeletedArticles,
      lightIIFAQ,
      lightIIIFAQ
    };
  }
);
