import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import cx from 'classnames';

import { Image } from 'components/base';

class Dropdown extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      menuIsOpen: false
    };
  }

  onChange = (value) => {
    this.props.onChange(value);
  };

  onOpen = () => {
    this.setState({ menuIsOpen: true });
  };

  onClose = () => {
    this.setState({ menuIsOpen: false });
  };

  getDropdownIndicator = (color) => {
    switch (color) {
      case 'white':
        return (
          <Image
            src="/assets/images/magnifying-glass-white.svg"
            alt="dropdown arrow white"
          />
        );
      default:
        return (
          <Image
            src="/assets/images/magnifying-glass-black.svg"
            alt="dropdown arrow black"
          />
        );
    }
  };

  dropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="wh100">
            {this.getDropdownIndicator(this.props.color)}
          </div>
        </components.DropdownIndicator>
      )
    );
  };

  render() {
    const {
      name,
      value,
      options,
      label,
      placeholder,
      variant,
      color,
      className,
      selectClassName,
      ariaLabel,
      disabled
    } = this.props;
    return (
      <div
        className={cx(
          className,
          `Dropdown relative z-1 className Dropdown--style-${variant} Dropdown--color-${color}`,
          {
            'disabledCursor': disabled,
          },
          {
            'pointer': !disabled,
          }
        )}
      >
        {label ? (
          <label
            className="Dropdown--label w100 inline-block mb1 bold small"
            htmlFor={name}
          >
            {label}
          </label>
        ) : null}
        <Select
          className={cx('Dropdown__select relative', selectClassName, {
            'Dropdown--open': this.state.menuIsOpen
          })}
          name={name}
          value={value}
          options={options}
          isClearable={false}
          isSearchable={true}
          onMenuOpen={this.onOpen}
          onMenuClose={this.onClose}
          placeholder={placeholder}
          onChange={this.onChange}
          classNamePrefix="Select"
          components={{ DropdownIndicator: this.dropdownIndicator }}
          aria-label={`${ariaLabel} dropdown menu`}
          isDisabled={disabled}
        />
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  options: [{}],
  name: '',
  placeholder: 'Select',
  className: '',
  selectClassName: '',
  label: '',
  value: '',
  variant: 'primary',
  onChange: () => {},
  color: 'black',
  ariaLabel: '',
  disabled: true,
};

export default Dropdown;
