import get from './get';
import generateNextPathWithParams from 'utils/generateNextPathWithParams';

const goBack = (props) => {
  const history = get(props, 'history', {});

  return get(props, 'routerLocations', []).length > 1
    ? history.goBack()
    : history.push(generateNextPathWithParams('/'));
};

export default goBack;
