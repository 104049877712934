import { GraphQLClient, Variables, RequestDocument } from 'graphql-request';
import { Mutation, QueryRoot } from 'types/generated-shopify';
import ENV from 'config/Environment';

/**
 * Config
 */
const token = ENV.SHOPIFY_STOREFRONT_TOKEN;
const shopName = ENV.SHOPIFY_STOREFRONT_SHOP_NAME;

if (!token) {
  throw new Error('No SHOPIFY_STOREFRONT_TOKEN was provided');
}

if (!shopName) {
  throw new Error('No SHOPIFY_STOREFRONT_DOMAIN was provided');
}

const ENDPOINT = `https://${shopName}.myshopify.com/api/2023-10/graphql`;

const client = new GraphQLClient(ENDPOINT, {
  headers: {
    Accept: 'application/json',
    'X-Shopify-Storefront-Access-Token': ENV.SHOPIFY_STOREFRONT_TOKEN
  }
});

/**
 * Base utils
 */

export const query = (query: RequestDocument, variables?: Variables) =>
  client.request<QueryRoot>(query, variables);

export const mutate = (mutation: RequestDocument, variables?: Variables) =>
  client.request<Mutation>(mutation, variables);
