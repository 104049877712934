import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import globalSettingsSelector from 'state/selectors/globalSettings';
import { fetchPhoneFlowProducts } from 'state/actions/shopifyCatalogueActions';
import { AppState, Dispatch } from 'state/types';

class LightPhoneFlowContainer extends ContainerBase {
  // @ts-ignore
  view = import('views/LightPhoneFlow');

  model = async () => {
    /* Matched from the regex in the route:
     *
     * path="/shop/products/light-phone-ii-(light|dark)"
     */
    const colorParam = this.props.match.params[0] as 'light-gray' | 'black';
    const color =
      colorParam === 'light-gray'
        ? 'light'
        : colorParam === 'black'
        ? 'dark'
        : null;

    if (!color) {
      this.props.history.push('/shop/products/light-phone-ii-black');
    }
    const existingProducts =
      this.props.lightPhoneFlowProducts; /* may be null */
    /* Return existing products if available, otherwise fetch them */
    const phoneFlowProducts =
      existingProducts ||
      (await this.props.actions
        .fetchPhoneFlowProducts()
        .then(({ value }) => value));
    return { color, phoneFlowProducts };
  };
}

const mapStateToProps = (state: AppState) => ({
  lightPhoneFlowProducts: state.shopifyCatalogue.phoneFlowProducts,
  globalSiteSettings: globalSettingsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchPhoneFlowProducts }, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-ignore
export default connector(LightPhoneFlowContainer);
