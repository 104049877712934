import {
  AttributeInput,
  CartInput,
  CartLineInput,
  CartLineUpdateInput
} from 'types/generated-shopify';

import { mutate, query } from 'lib/Shopify/client';
import { CART_CREATE_MUTATION } from 'lib/Shopify/queries/cartCreate';
import { CART_FETCH_QUERY } from 'lib/Shopify/queries/cartFetch';
import { CART_LINE_ITEMS_UPDATE_MUTATION } from 'lib/Shopify/queries/cartLineItemsUpdate';
import { Dispatch } from '../types';
import { CART_LINE_ITEMS_ADD_MUTATION } from 'lib/Shopify/queries/cartLineItemsAdd';
import { arrayify } from 'utils/arrays';
import { UPDATE_CART_ATTRIBUTES_MUTATION } from 'lib/Shopify/queries/cartAttributesUpdate';

export const OPEN_CART = 'OPEN_CART';
export const openCart = () => (dispatch: Dispatch) =>
  dispatch({ type: OPEN_CART });

export const CLOSE_CART = 'CLOSE_CART';
export const closeCart = () => (dispatch: Dispatch) =>
  dispatch({ type: CLOSE_CART });

export const FETCH_CART = 'FETCH_CART';
export const FETCH_CART_PENDING = 'FETCH_CART_PENDING';
export const FETCH_CART_FULFILLED = 'FETCH_CART_FULFILLED';
export const fetchCart = (cartId: string) => (dispatch: Dispatch) =>
  dispatch({
    type: FETCH_CART,
    payload: async () => {
      const variables = { id: cartId };
      const result = await query(CART_FETCH_QUERY, variables);
      return result.cart;
    }
  });

export const CREATE_CART = 'CREATE_CART';
export const CREATE_CART_PENDING = 'CREATE_CART_PENDING';
export const CREATE_CART_FULFILLED = 'CREATE_CART_FULFILLED';
export const createCart = (input?: CartInput) => (dispatch: Dispatch) =>
  dispatch({
    type: CREATE_CART,
    payload: async () => {
      const result = await mutate(CART_CREATE_MUTATION, { input });
      const cart = result?.cartCreate?.cart;
      const userErrors = result?.cartCreate?.userErrors;
      if (!cart) {
        throw new Error('Could not create cart');
      }
      return { cart, userErrors };
    }
  });

export const ADD_LINE_ITEMS = 'ADD_LINE_ITEMS';
export const ADD_LINE_ITEMS_PENDING = 'ADD_LINE_ITEMS_PENDING';
export const ADD_LINE_ITEMS_FULFILLED = 'ADD_LINE_ITEMS_FULFILLED';
export const addLineItems =
  (cartId: string, lines: CartLineInput[]) => (dispatch: Dispatch) =>
    dispatch({
      type: ADD_LINE_ITEMS,
      payload: async () => {
        const variables = { cartId, lines };
        const result = await mutate(CART_LINE_ITEMS_ADD_MUTATION, variables);
        return result.cartLinesAdd;
      }
    });

export const UPDATE_LINE_ITEMS = 'UPDATE_LINE_ITEMS';
export const UPDATE_LINE_ITEMS_PENDING = 'UPDATE_LINE_ITEMS_PENDING';
export const UPDATE_LINE_ITEMS_FULFILLED = 'UPDATE_LINE_ITEMS_FULFILLED';
export const updateLineItems =
  (cartId: string, lines: CartLineUpdateInput[]) => (dispatch: Dispatch) =>
    dispatch({
      type: UPDATE_LINE_ITEMS,
      payload: async () => {
        const result = await mutate(CART_LINE_ITEMS_UPDATE_MUTATION, {
          lines,
          cartId: cartId
        });
        return result.cartLinesUpdate;
      }
    });

export const UPDATE_CART_ATTRIBUTES = 'UPDATE_CART_ATTRIBUTES';
export const UPDATE_CART_ATTRIBUTES_PENDING = 'UPDATE_CART_ATTRIBUTES_PENDING';
export const UPDATE_CART_ATTRIBUTES_FULFILLED =
  'UPDATE_CART_ATTRIBUTES_FULFILLED';
export const updateCartAttributes =
  (cartId: string, attributes: AttributeInput | AttributeInput[]) =>
  (dispatch: Dispatch) => {
    const inputs = arrayify(attributes);
    dispatch({
      type: UPDATE_CART_ATTRIBUTES,
      payload: async () => {
        const result = await mutate(UPDATE_CART_ATTRIBUTES_MUTATION, {
          cartId,
          attributes: inputs
        });
        return result.cartAttributesUpdate;
      }
    });
  };
