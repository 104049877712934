import { createSelector } from 'reselect';
import get from 'utils/get';
import flattenImageData from 'utils/flattenImageData';
import slugify from 'utils/slugify';

export default createSelector(
  (state) => get(state, 'compatibilityCheck.compatibilityCheck', {}),
  (contentfulResponse) => {
    const fields = get(contentfulResponse, 'items[0].fields', {});
    const logo = flattenImageData(get(fields, 'logo', {}));
    const textUnderLogo = get(fields, 'textUnderLogo', '');
    const titleText = get(fields, 'titleText', '');
    const backgroundHexColor = get(fields, 'backgroundHexColor', '#FFF');
    const iconColor = slugify(get(fields, 'iconColor', 'Black'));
    const devicePickerTitle = get(fields, 'devicePickerTitle', '');
    const lightIiImage = flattenImageData(get(fields, 'lightIiImage', {}));
    const lightIiiImage = flattenImageData(get(fields, 'lightIiiImage', {}));
    //Compatibility region variant.
    const variants = get(fields, 'variants', []).reduce(
      (sanitizedVariants, variant) => {
        const contentfulId = get(variant, 'sys.id', '');
        const fields = get(variant, 'fields', {});
        const id = get(fields, 'variantId', contentfulId);
        const contentBlocks = get(fields, 'contentBlocks', []);
        const countries = get(fields, 'countries.simpleFragments', {});
        const text = get(fields, 'text', '');
        const textColor = slugify(get(fields, 'textColor', 'Black'));
        const textWidth = slugify(get(fields, 'textWidth', 'Medium'));

        const countryCodes = Object.values(countries).reduce(
          (countryCodes, country) => {
            const countryCode = get(country, 'countryCode', '');
            const compatibleCarriers = get(country, 'compatibleCarriers', '');
            const incompatibleCarriers = get(
              country,
              'incompatibleCarriers',
              ''
            );
            countryCodes[countryCode] = {
              compatibleCarriers,
              incompatibleCarriers
            };

            return countryCodes;
          },
          {}
        );

        sanitizedVariants[id] = {
          contentBlocks,
          countryCodes,
          text,
          textColor,
          textWidth
        };

        return sanitizedVariants;
      },
      {}
    );
    const variantIds = Object.keys(variants);

    return {
      logo,
      textUnderLogo,
      titleText,
      backgroundHexColor,
      iconColor,
      variants,
      variantIds,
      devicePickerTitle,
      lightIiImage,
      lightIiiImage
    };
  }
);
