import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import get from 'utils/get';

import { fetchGenericPage } from 'state/actions/genericPageActions';
import {
  fetchModalPage,
  openModalPage,
  closeModalPage
} from 'state/actions/modalPageActions';
import globalSettings from 'state/selectors/globalSettings';

class GenericPageContainer extends ContainerBase {
  view = import('views/GenericView');

  model = () => {
    const { fetchGenericPage, fetchModalPage } = this.props.actions;
    const { match } = this.props;
    const params = get(match, 'params.genericPageSlug', '');

    return Promise.all([
      fetchGenericPage(`/${params}`),
      fetchModalPage(`/${params}`)
    ]).then(([genericPage, modalGenericPage]) => ({
      genericPage: get(genericPage, 'value'),
      modalGenericPage: get(modalGenericPage, 'value')
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    globalSettings: globalSettings(state),
    genericPage: get(state, 'genericPage.genericPage.items', []),
    modalGenericPage: get(state, 'modalPage.modalPage.items', []),
    routerLocations: get(state, 'routerLocations', []),
    fetchGenericPageStatus: get(state, 'status.fetchGenericPage'),
    fetchModalPageStatus: get(state, 'status.fetchModalPage')
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchGenericPage, fetchModalPage, openModalPage, closeModalPage },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericPageContainer);
