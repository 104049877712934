import { LOCATION_CHANGE } from 'react-router-redux';

export default function routerLocations(state = [], action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return [...state, action.payload];
    default:
      return state;
  }
}
