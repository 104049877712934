import * as Types from 'lib/types';
import get from 'utils/get';
import { FETCH_ALL_ARTICLES } from 'state/actions/allArticlesActions';
import flattenImageData from 'utils/flattenImageData';

interface AllArticlesState {
  allArticles: Types.Article[];
}

const initialState: AllArticlesState = {
  allArticles: []
};

type Action = {
  type: string;
  payload: {
    items: Types.Article[];
  };
};

const reducer = (state = initialState, action: Action): AllArticlesState => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ALL_ARTICLES}_FULFILLED`:
      const articles = payload?.items ?? [];
      const formattedArticles = articles.map((article) => {
        const slug: string = get(article, 'fields.slug', '');
        const title: string = get(article, 'fields.title', '');
        const createdAt: Date = new Date(
          get(article, 'fields.createdAt', null)
        );
        const contentBlocks = get(article, 'fields.contentBlocks', []);
        const highlightImage = flattenImageData(
          get(article, 'fields.highlightImage', {})
        );
        const tags: string[] = get(article, 'fields.tags', []);
        const id: string = get(article, 'sys.id', '');

        return {
          slug,
          title,
          createdAt,
          contentBlocks,
          highlightImage,
          tags,
          id
        };
      });
      return {
        ...state,
        allArticles: formattedArticles
      };
    case `${FETCH_ALL_ARTICLES}_REJECTED`:
      return {
        ...state,
        allArticles: []
      };
    default:
      return state;
  }
};

export default reducer;
