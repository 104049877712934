import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import ENV from 'config/Environment';
import get from 'utils/get';
import flattenedImageModel from 'models/flattenedImageModel';

import { Button, Image } from 'components/base';
import EmailSignup from 'components/EmailSignup';

const Footer = React.memo(
  ({
    logo,
    address,
    socialIcons,
    footerBottomLinks,
    openInNewTab,
    location,
    menu
  }) => {
    const getLink = (link) =>
      openInNewTab ? `${ENV.LIGHT_SITE_URL}${link}` : link;

    const linkIsActive = (link) => get(location, 'pathname', '') === link;

    return (
      <>
        <div className="Footer relative wh100 bg-color-gray py2 flex flex-col items-center">
          <div className="wh100 mx1_5 md:mx4 flex flex-wrap flex-col md:flex-row items-center justify-center">
            {menu.map((menuField) => {
              const name = menuField.name;
              const link = menuField.link;
              const addOnlyToPopoverMenu = menuField.addOnlyToPopoverMenu;

              if (addOnlyToPopoverMenu) {
                return null;
              }

              return (
                <Button
                  ariaLabel={`navigate to ${name}`}
                  key={name}
                  to={getLink(link)}
                  className={cx('Footer__link', {
                    'Footer__link--active': linkIsActive(link)
                  })}
                  label={name}
                  newTab={openInNewTab}
                />
              );
            })}
          </div>
          <EmailSignup />
          <div className="Footer__social-icon-container mx1_5 md:mx4 mb8 flex flex-row flex-wrap items-center justify-center">
            {socialIcons.map((socialIcon, i) => {
              const icon = get(socialIcon, 'icon', {});
              return (
                <div className="m_75" key={get(socialIcon, 'uuid', i)}>
                  <Button
                    ariaLabel={`open ${socialIcon.link}`}
                    hover="down-small"
                    to={socialIcon.link}
                  >
                    <Image
                      className="Footer__social-icon"
                      src={get(icon, 'data', '')}
                      alt={get(icon, 'name', 'social icon')}
                    />
                  </Button>
                </div>
              );
            })}
          </div>
          <Image
            className="Footer__logo mx1_5 md:mx4 my_5 md:my0"
            src={logo.url}
            alt="Light Logo White"
          />
          <p className="mx1_5 md:mx4 my1 md:my1 futura-pt color-white detail detail-spacing text-center medium-opacity">
            {address}
          </p>
          <div className="wh100 px1_5 md:px4 single-column-content-width-lg flex flex-row items-center justify-center">
            {footerBottomLinks.map((footerBottomLink) => (
              <Button
                ariaLabel={`navigate to ${footerBottomLink.name}`}
                key={footerBottomLink.name}
                to={getLink(footerBottomLink.link)}
                className="Footer__link-small futura-pt font-md text-decoration-none detail mx1 md:mx1_5"
                label={footerBottomLink.name}
                newTab={openInNewTab}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
);

Footer.propTypes = {
  logo: flattenedImageModel.propTypes,
  address: PropTypes.string,
  socialIcons: PropTypes.array,
  footerBottomLinks: PropTypes.array,
  openInNewTab: PropTypes.bool,
  menu: PropTypes.array
};

Footer.defaultProps = {
  logo: flattenedImageModel.default,
  address: '',
  socialIcons: [],
  footerBottomLinks: [],
  openInNewTab: false,
  menu: []
};

export default Footer;
