import { Cart, CartUserError } from 'types/generated-shopify';
import { Status } from 'constants/Status';

import {
  ADD_LINE_ITEMS_FULFILLED,
  ADD_LINE_ITEMS_PENDING,
  CLOSE_CART,
  CREATE_CART_FULFILLED,
  CREATE_CART_PENDING,
  FETCH_CART_FULFILLED,
  FETCH_CART_PENDING,
  OPEN_CART,
  UPDATE_CART_ATTRIBUTES_FULFILLED,
  UPDATE_CART_ATTRIBUTES_PENDING,
  UPDATE_LINE_ITEMS_FULFILLED,
  UPDATE_LINE_ITEMS_PENDING
} from '../actions/shopifyCartActions';

/**
 * State
 */

export interface ShopifyCartState {
  userErrors: CartUserError[];
  cart: Cart | void | null;
  isOpen: boolean;
  status: Status;
}

type CartAction =
  | {
      type:
        | typeof CREATE_CART_PENDING
        | typeof FETCH_CART_PENDING
        | typeof UPDATE_LINE_ITEMS_PENDING
        | typeof ADD_LINE_ITEMS_PENDING
        | typeof UPDATE_CART_ATTRIBUTES_PENDING;
    }
  | {
      type:
        | typeof UPDATE_LINE_ITEMS_FULFILLED
        | typeof ADD_LINE_ITEMS_FULFILLED
        | typeof CREATE_CART_FULFILLED
        | typeof UPDATE_CART_ATTRIBUTES_FULFILLED;
      payload: { cart?: Cart | null; userErrors?: CartUserError[] };
    }
  | {
      type: typeof FETCH_CART_FULFILLED;
      payload: Cart;
    }
  | { type: typeof OPEN_CART | typeof CLOSE_CART };

const initialState: ShopifyCartState = {
  userErrors: [],
  cart: null,
  isOpen: false,
  status: Status.IDLE
};

const cartReducer = (
  state: ShopifyCartState = initialState,
  action: CartAction
): ShopifyCartState => {
  switch (action.type) {
    case OPEN_CART:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE_CART:
      return {
        ...state,
        isOpen: false
      };
    case FETCH_CART_PENDING:
    case CREATE_CART_PENDING:
    case UPDATE_LINE_ITEMS_PENDING:
    case ADD_LINE_ITEMS_PENDING:
    case UPDATE_CART_ATTRIBUTES_PENDING:
      return {
        ...state,
        status: Status.PENDING
      };
    case FETCH_CART_FULFILLED:
      return {
        ...state,
        status: Status.FULFILLED,
        cart: action.payload
      };
    case CREATE_CART_FULFILLED:
    case ADD_LINE_ITEMS_FULFILLED:
    case UPDATE_LINE_ITEMS_FULFILLED:
    case UPDATE_CART_ATTRIBUTES_FULFILLED:
      const { cart, userErrors } = action.payload;
      return {
        ...state,
        cart,
        status: Status.FULFILLED,
        userErrors: userErrors || []
      };

    default:
      return state;
  }
};

export default cartReducer;
