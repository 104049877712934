import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import get from 'utils/get';

import { fetchCompatibilityCheck } from 'state/actions/compatibilityCheckActions';
import { fetchPhoneFlowProducts } from 'state/actions/shopifyCatalogueActions';
import compatibilityCheckByRegion from 'state/selectors/compatibilityCheckByRegion';

class CompatibilityCheck extends ContainerBase {
  view = import('views/CompatibilityCheckView');

  model = async () => {
    const { fetchCompatibilityCheck, fetchPhoneFlowProducts } = this.props.actions;
    const compatibilityCheck = await fetchCompatibilityCheck();
    const phoneFlowProducts = await fetchPhoneFlowProducts();

    return { compatibilityCheck, phoneFlowProducts };
  };
}

const mapStateToProps = (state, props) => {
  return {
    routerLocations: get(state, 'routerLocations', []),
    compatibilityCheck: compatibilityCheckByRegion(state),
    lightPhoneFlowProducts: state.shopifyCatalogue.phoneFlowProducts
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchCompatibilityCheck, fetchPhoneFlowProducts }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompatibilityCheck);
