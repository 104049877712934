const browserSupportsWebP = (function browserSupportsWebP() {
  try {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
})();

const defaults = {
  width: '1024',
  format: browserSupportsWebP ? 'webp' : 'jpg&fl=progressive',
  quality: '90'
};

const contentfulImgUtil = (url, width = defaults.width, format) => {
  if (!url) return '';

  if (!format && url) {
    const imageFormat = url.split('.').pop();

    if (imageFormat === 'gif') {
      return url;
    }

    const getFormat =
      imageFormat === 'png' && !browserSupportsWebP ? 'png' : defaults.format;

    return `${url}?w=${width}&fm=${getFormat}&q=85`;
  }

  return `${url}?w=${width}&fm=${format || defaults.format}&q=90`;
};

export default contentfulImgUtil;
