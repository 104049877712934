import { FETCH_GLOBAL_SETTINGS } from 'state/actions/ui/applicationUIActions';

const initialState = {
  globalSettings: {}
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case `${FETCH_GLOBAL_SETTINGS}_FULFILLED`:
      return {
        ...state,
        globalSettings: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
