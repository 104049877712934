import { OPEN_MENU, CLOSE_MENU } from 'state/actions/ui/navUIActions';

const initialState = {
  menuIsOpen: false
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case OPEN_MENU:
    case CLOSE_MENU:
      return {
        ...state,
        menuIsOpen: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
