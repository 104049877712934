import Content from 'lib/Content';

export const FETCH_COMPATIBILITY_CHECK = 'FETCH_COMPATIBILITY_CHECK';
export const fetchCompatibilityCheck = (path) => (dispatch) => {
  return dispatch({
    type: FETCH_COMPATIBILITY_CHECK,
    payload: Content.getEntries({
      content_type: 'compatibilityCheck',
      include: 4
    })
  });
};
