import gql from 'graphql-tag';
import { cartFragment } from './sharedFragments';

export const UPDATE_CART_ATTRIBUTES_MUTATION = gql`
  mutation CartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`;
