import { query } from 'lib/Shopify/client';
import { FETCH_COLLECTIONS_QUERY } from 'lib/Shopify/queries/collections';
import { FETCH_PHONE_FLOW_PRODUCTS_QUERY } from 'lib/Shopify/queries/phoneFlow';
import { FETCH_PRODUCT_QUERY } from 'lib/Shopify/queries/products';
import { Dispatch } from 'state/types';
import { getPaginatedNodes } from 'utils/graphql';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_FULFILLED = 'FETCH_COLLECTIONS_FULFILLED';
export const fetchCollections = () => (dispatch: Dispatch) =>
  dispatch({
    type: FETCH_COLLECTIONS,
    payload: async () => {
      const result = await query(FETCH_COLLECTIONS_QUERY);
      return getPaginatedNodes(result.collections);
    }
  });

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_FULFILLED = 'FETCH_PRODUCT_FULFILLED';

export const fetchProduct = (productHandle: string) => (dispatch: Dispatch) =>
  dispatch({
    type: FETCH_PRODUCT,
    payload: async () => {
      const result = await query(FETCH_PRODUCT_QUERY, {
        handle: productHandle
      });
      return result.product;
    }
  });

export const FETCH_PHONE_FLOW_PRODUCTS = 'FETCH_PHONE_FLOW_PRODUCTS';
export const FETCH_PHONE_FLOW_PRODUCTS_FULFILLED =
  'FETCH_PHONE_FLOW_PRODUCTS_FULFILLED';

export const fetchPhoneFlowProducts = () => (dispatch: Dispatch) =>
  dispatch({
    type: FETCH_PHONE_FLOW_PRODUCTS,
    payload: async () => {
      const result = await query(FETCH_PHONE_FLOW_PRODUCTS_QUERY);
      return result;
    }
  });
